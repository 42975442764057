import React, { useState } from "react"
import styled from "styled-components"
import hexToRgba from "../../utils/hexToRgba"

import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

import { Gallery, Item } from "react-photoswipe-gallery"
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import { Margin } from "styled-components-spacing"
import { Section, Center, Line } from "../styled/layout"
import { Title, Content } from "../styled/typography"
import LazyImage from "../styled/lazyImage"
import Button from "../styled/button"

const GallerySection = styled(Section)`
  padding: 40px 0;

  /* .pswp__button--zoom {
    display: block;
  } */
`

const GalleryImage = styled(LazyImage)`
  height: 220px !important;

  ${media.sm`  
    height: 160px !important;
  `}

  ${media.md`  
    height: 150px !important;
  `}

  ${media.lg`  
    height: 200px !important;
  `}

  ${media.xl`  
    height: 250px !important;
  `}

  box-shadow: 0px 2px 10px ${({ theme }) => hexToRgba(theme.colors.dark, 0.1)};
  transition: box-shadow ease-in-out 200ms;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 25px
      ${({ theme }) => hexToRgba(theme.colors.dark, 0.25)};
  }

  
`

const GalleryCol = styled(Col)`
  margin-bottom: 1.5rem;
`

const MyGallery = ({ title, description, images, ...props }) => {
  const initialImages = 6
  const [visibleImages, setVisibleImages] = useState(initialImages)

  const imagesDOM = [...images].slice(0, visibleImages).map(el => (
    <Item
      original={el.original}
      width={el.width}
      height={el.height}
      title={el.alt || null}
      key={el.id}
    >
      {({ ref, open }) => (
        <GalleryCol
          md="4"
          sm="6"
          col="12"
          ref={ref}
          onClick={open}
        >
          <GalleryImage src={el.thumbnail} cover />
        </GalleryCol>
      )}
    </Item>
  ))

  return (
    <GallerySection {...props}>
      <Container>
        {title.length > 0 && (
          <Margin as="header" bottom="3">
            <Title uppercase align="center">
              {title}
            </Title>
            <Line center width="calc(8% + 40px)"></Line>
          </Margin>
        )}

        <Content dangerouslySetInnerHTML={{ __html: description }}></Content>

        <Row>
          <Gallery
            options={{
              // showAnimationDuration: 0,
              // hideAnimationDuration: 0,
              // TODO: Better zooming
              shareEl: false,
              clickToCloseNonZoomable: false,
              getDoubleTapZoom: function (isMouseClick, item) {
                if (item.initialZoomLevel < 0.4) {
                  return 0.9
                }
                if (item.initialZoomLevel < 0.7) {
                  return 1.2
                }
                if (item.initialZoomLevel < 1) {
                  return 1.8
                }
                return 2
                // }
                // if (isMouseClick) {
                //   return 2.5
                // } else {
                //   return item.initialZoomLevel < 0.7 ? 2.5 : 1.33
                // }
              },
              maxSpreadZoom: 2,
            }}
          >
            {imagesDOM}
          </Gallery>
        </Row>
        {images.length > visibleImages && (
          <Center>
            <Button
              primary
              onClick={() => setVisibleImages(visibleImages + initialImages)}
            >
              Więcej
            </Button>
          </Center>
        )}
      </Container>
    </GallerySection>
  )
}

MyGallery.defaultProps = {
  images: [],
  title: "",
}

export default MyGallery
